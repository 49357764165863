<template>
  <div v-if="model" style="word-break: break-word">
    <ul v-for="(value, name) in model" :key="name">
      <li>
        <div v-if="typeof(value) === 'object' && value !== null">
          <div v-html="getHeaderHtmlRaw(name)"></div>
          <json-tree-display :parent="name" :model.sync="value" :level="level+1"></json-tree-display>
        </div>
        <div v-else style="display:flex">
          <label style="flex: 1">{{ sanitizeName(name) }}:</label>&nbsp;
          <span style="flex: 5">{{value}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "jsonTreeDisplay",
  props: {
    level: {
      default: 3,
      type: Number
    },
    model: Object,
    parent: String
  },
  methods: {
    getHeaderHtmlRaw(value) {
      return `<h${this.level}>${value}</h${this.level}>`;
    },
    sanitizeName(value) {
      if (this.parent == null) return value;

      var str = value.replace(this.parent, "");

      var parent = this.parent;
      var parentWithoutS = this.trimRight(this.parent);

      var str = str.replace(parentWithoutS, "");

      return this.trimLeft(str, "_");
    },
    trimRight(str, charlist) {
      if (charlist === undefined) charlist = "s";

      return str.replace(new RegExp("[" + charlist + "]+$"), "");
    },
    trimLeft(str, charlist) {
      if (charlist === undefined) charlist = "s";

      return str.replace(new RegExp("^[" + charlist + "]+"), "");
    }
  },
  components: {}
};
</script>

